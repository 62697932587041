<div id="experience" class="offset">
  <div class="container-fluid px-0">
    <div class="row justify-content-center py-5">
      <div class="col-12 mt-5 os-animation" data-aos="fade-up">
        <h3 class="heading">Experience</h3>
        <div class="heading-underline"></div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-sm-6 col-xs-12 card" data-aos="fade-left">
        <div>
          <a href="https://www.cbre.us" target="_blank"
            ><img
              class="card-img-top"
              src="../../assets/img/companies/CBRE.png"
              alt="Card image cap"
          /></a>
        </div>
        <div class="card-body">
          <div class="card-text">
            <b>Principal Software Engineer</b> (2022-Present)
            <ul>
              <li>
                Leading a key, high-impact project for CBRE Investment
                Management, collaborating with international stakeholders to
                define requirements and maintain comprehensive documentation.
              </li>
              <li>
                Mentoring and guiding a US and offshore team, ensuring on-time
                delivery.
              </li>
              <li>
                Developing architectural documentation and diagrams to
                facilitate understanding and alignment across management and
                technical teams.
              </li>
              <li>
                Facilitating knowledge sharing and collaboration with other
                teams to extend the solution's principles across the
                organization.
              </li>
              <li>
                Serving as a liaison between business and technical
                stakeholders, effectively communicating project status and
                implementation details through presentations.
              </li>
            </ul>
            <b>Senior Software Engineer</b> (2019-2022)
            <ul>
              <li>
                Architected, led and built GI Global Investors UI rewrite
                project in Angular
              </li>
              <li>Coached, trained and led offshore team</li>
              <li>Designed and architected the structure of projects</li>
              <li>
                Implemented the best solution for UI projects using Angular
                Routing, HttpClientModule, Ngrx (Redux pattern), Lazy loading,
                AOT, feature modules, component communication
              </li>
              <li>
                Coded with best practices and always strived for code quality,
                clean code, refactoring
              </li>
              <li>
                Implemented design patterns (stategy, observer, dependency
                injection, abstract builder, factory)
              </li>
              <li>Unit Testing</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-md-5">
      <div
        class="col-md-3 col-sm-6 card align-self-stretch"
        data-aos="fade-left"
      >
        <div class="align-self-stretch">
          <a href="https://www.ksquareinc.com" target="_blank"
            ><img
              class="card-img-top"
              src="../../assets/img/companies/ksquare.png"
              alt="Card image cap"
          /></a>
        </div>
        <div class="card-body">
          <div class="card-text">
            Contractor working at CBRE
            <div><b>Senior Software Engineer</b> (2018-2019)</div>
            <ul>
              <li>
                Architected, led and built GI Global Investors UI rewrite
                project in Angular
              </li>
              <li>Designed and architected the structure of projects</li>
              <li>
                Implemented the best solution for UI projects using Angular
                Routing, HttpClientModule, Ngrx (Redux pattern), Lazy loading,
                AOT, feature modules, component communication
              </li>
              <li>
                Coded with best practices and always strived for code quality,
                clean code, refactoring
              </li>
              <li>
                Implemented design patterns (stategy, observer, dependency
                injection, abstract builder, factory)
              </li>
              <li>Unit Testing</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-3 col-sm-6 card" data-aos="fade-down">
        <div>
          <a href="https://www.infolob.com" target="_blank"
            ><img
              class="card-img-top"
              src="../../assets/img/companies/infolob.png"
              alt="Card image cap"
          /></a>
        </div>
        <div class="card-body">
          <div class="card-text">
            Contractor working at CBRE
            <ul>
              <li>
                Architected, led and built GI Global Investors UI rewrite
                project in Angular
              </li>
              <li>Designed and architected the structure of projects</li>
              <li>
                Implemented the best solution for UI projects using Angular
                Routing, HttpClientModule, Ngrx (Redux pattern), Lazy loading,
                AOT, feature modules, component communication
              </li>
              <li>
                Coded with best practices and always strived for code quality,
                clean code, refactoring
              </li>
              <li>
                Implemented design patterns (stategy, observer, dependency
                injection, abstract builder, factory)
              </li>
              <li>Unit Testing</li>
            </ul>
          </div>
        </div>
      </div> -->
      <div class="col-md-3 col-sm-6 card" data-aos="fade-right">
        <div>
          <a href="https://www.mobiik.com/" target="_blank"
            ><img
              class="card-img-top"
              src="../../assets/img/companies/mobiik.png"
              alt="Card image cap"
          /></a>
        </div>
        <div class="card-body">
          <div class="card-text">
            <b>Expert Developer Front End Full Stack</b> (2018)
            <ul>
              <li>
                Worked for a project for the Mexican Government (PGR FEPADE) for
                Mexican elections of 2018
              </li>

              <li>
                Made all the Front end in Angular 5, with best practices and
                separation of concerns
              </li>

              <li>Worked with Boostrap and ng Bootstrap</li>

              <li>
                Contributed on building the API Rest in .Net and designed the
                SQL DB
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-sm-5">
      <div class="col-md-3 col-sm-6 card" data-aos="fade-left">
        <div>
          <a href="https://www.gbm.com.mx" target="_blank"
            ><img
              class="card-img-top"
              src="../../assets/img/companies/colour_GBM.png"
              alt="Card image cap"
          /></a>
        </div>
        <div class="card-body">
          <div class="card-text">
            <b>GBM (Grupo Bursatil Mexicano)</b> (2014-2018)
            <ul>
              <li>
                Worked for several projects inside the company including home
                broker system, dividend payments and trusts management
              </li>

              <li>
                Worked with several technologies as a full stack developer
              </li>

              <li>
                In the Frontend worked with Angular 4, React, Vue, Bootstrap and
                Angular Material
              </li>

              <li>
                In the API side worked on .NET Core in combination with WCF
                services
              </li>

              <li>
                For the DB worked on migration of contracts from clients, as
                well as several store procedures and functions in SQL
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 card" data-aos="fade-down">
        <div>
          <a href="https://www.syc.com.mx" target="_blank"
            ><img
              class="card-img-top"
              src="../../assets/img/companies/syc-p.png"
              alt="Card image cap"
          /></a>
        </div>
        <div class="card-body">
          <div class="card-text">
            <b>Software Engineer Java</b> (2012-2014)
            <ul>
              <li>Worked for CONAGUA project (Mexican government)</li>

              <li>
                Primarly worked on the module to manage expenses of this
                government department
              </li>

              <li>Technologies used were java,</li>

              <li>
                In the API side worked on .NET Core in combination with WCF
                services
              </li>

              <li>
                For the DB worked on migration of contracts from clients, as
                well as several store procedures and functions in SQL
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 card" data-aos="fade-right">
        <div>
          <a href="https://www.itam.com.mx" target="_blank"
            ><img
              class="card-img-top"
              src="../../assets/img/companies/itam.png"
              alt="Card image cap"
          /></a>
        </div>
        <div class="card-body">
          <div class="card-text">
            <ul>
              <li>Member of the videogames lab</li>
              <li>
                Entered the contest of 2008 imagine cup, making to the second
                round
              </li>
              <li>The game was developed with XNA</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 text-center pt-5 os-animation" data-aos="fade-bottom">
        <a
          (click)="scroll('contact')"
          class="btn btn-primary btn-sm color-white"
          >Get In Touch</a
        >
      </div>
    </div>
    <!-- End Container -->
  </div>
</div>
