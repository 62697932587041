<app-home></app-home>
<app-about-me></app-about-me>
<app-skills></app-skills>
<app-experience></app-experience>
<!-- <app-knowledge></app-knowledge> -->
<app-certificates></app-certificates>
<app-contact-me></app-contact-me>
<a href="#home" class="top-scroll">
  <i class="fa fa-angle-up"></i>
</a>
