<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img class="image" [src]="image" />
  </div>
</div>
