import { Component, OnInit } from '@angular/core';
import { NavBarService } from 'src/shared/nav-bar/nav-bar.service';

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.scss'],
})
export class KnowledgeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  scroll(element) {
    NavBarService.scroll(element);
  }
}
