import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnInit {
  @Input()
  images: any[];
  @Input()
  interval: number;
  @Output()
  imageClickedEmitter: EventEmitter<any> = new EventEmitter<any>();

  filteredImages;

  constructor() {}

  ngOnInit(): void {
    this.getFilteredImages();
  }

  retrieveNextElements() {
    this.interval += this.interval;
    this.getFilteredImages();
  }

  imageClicked(image) {
    this.imageClickedEmitter.emit(image);
  }

  openLinkInNewWindow(
    url: string,
    target: string = "_blank",
    features: string = ""
  ) {
    window.open(url, target, features);
  }

  private getFilteredImages() {
    this.filteredImages = this.images.slice(0, this.interval);
  }
}
