<div class="row">
  <div
    *ngFor="let image of filteredImages"
    class="col-sm-12 col-md-6 col-xl-4 pointer d-flex justify-content-center"
    data-aos="fade-up"
  >
    <div class="item">
      <span>{{ image.name }}</span>
      <span
        *ngIf="image.link"
        (click)="openLinkInNewWindow(image.link)"
        class="link"
        >View</span
      >
      <img class="image" (click)="imageClicked(image)" [src]="image.path" />
    </div>
  </div>
</div>
<div
  class="col-12 text-center pt-5 os-animation"
  data-aos="fade-bottom"
  *ngIf="interval <= filteredImages?.length"
>
  <a
    (click)="retrieveNextElements()"
    class="btn btn-primary btn-sm color-white"
  >
    See more</a
  >
</div>
