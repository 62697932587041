<div id="home">
  <app-nav-bar></app-nav-bar>
  <!-- Start Landing Page Image -->
  <div class="landing">
    <div class="home-wrap">
      <div class="home-inner"></div>
    </div>
  </div>
  <!-- End Landing Page Image -->

  <!-- Start Landing Page Caption -->
  <div
    class="caption text-center text-light text-uppercase"
    data-aos="fade-right"
  >
    <h1
      class="font-weight-bold os-animation"
      data-aos="fade-right"
      data-aos-mirror="true"
    >
      Fernando Morales
    </h1>
    <h3 class="os-animation" data-aos="fade-up" data-aos-delay="800">
      Principal Software Engineer
    </h3>

    <div class="margin-button">
      <a
        class="btn btn-outline-light btn-lg rounded-0 os-animation color-black"
        data-aos="fade-up"
        data-aos-delay="1000"
        href="assets/Fernando_Morales.pdf"
        download="Fernando-Morales.pdf"
        target="_blank"
        >Resume</a
      >
    </div>

    <a
      (click)="scroll('aboutMe')"
      class="btn btn-outline-light btn-lg rounded-0 os-animation color-black"
      data-aos="fade-up"
      data-aos-delay="1000"
      >Get Started</a
    >
  </div>
  <!-- End Landing Page Caption -->

  <!-- Start Bouncing Down Arrow -->
  <a (click)="scroll('aboutMe')" class="down-arrow text-center">
    <div class="arrow d-none d-md-block">
      <!-- <i class="fas fa-angle-down"></i> -->
      <fa-icon [icon]="faAngleDown"></fa-icon>
    </div>
  </a>
  <!-- End Bouncing Down Arrow -->
</div>
<!-- End Home Section -->
