import { Component, HostListener, OnInit } from "@angular/core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { NavBarService } from "./nav-bar.service";
import { DomSanitizer } from "@angular/platform-browser";
import * as FileSaver from "file-saver"; // Or 'save-blob' if you prefer

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  displaySolid = false;
  pdfUrl: any; // Make pdfUrl of type any

  constructor(private sanitizer: DomSanitizer) {}

  faBars = faBars;
  @HostListener("window:scroll", ["$event"])
  setNavBarClassDark() {
    if (window.pageYOffset > 300) {
      this.displaySolid = true;
    } else {
      this.displaySolid = false;
    }
  }

  ngOnInit() {}

  scroll(element) {
    NavBarService.scroll(element);
  }

  downloadResume(event: Event) {
    console.log("In download resume");
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      "./Fernando_Morales.pdf"
    );
    console.log(this.pdfUrl);
    event.preventDefault();

    fetch(this.pdfUrl) // Fetch the PDF using the URL from DomSanitizer
      .then((response) => response.blob()) // This line creates the Blob!
      .then((blob) => {
        FileSaver.saveAs(blob, "my-pdf-file.pdf"); // Use the Blob with FileSaver
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  }
}
