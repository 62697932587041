import { Component, OnInit } from '@angular/core';
import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faAngleDoubleDown, faCircle, faDesktop, faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {
  faCircle = faCircle;
  faAngleDoubleDown = faAngleDoubleDown;
  faPlay = faPlay;
  faDesktop = faDesktop;
  faJs = faJs;
  constructor() {}

  ngOnInit() {}
}
