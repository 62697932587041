import { Component, OnInit } from '@angular/core';
import { NavBarService } from 'src/shared/nav-bar/nav-bar.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  scroll(element) {
    NavBarService.scroll(element);
  }
}
