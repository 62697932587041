<div id="contact" class="offset">
  <div class="container-fluid footer px-0">
    <div class="row outer">
      <div class="container">
        <div class="row py-3">
          <div class="col-md-5 px-0 pr-md-3">
            <strong>Contact Info</strong>
            <p>findfernandomorales@gmail.com</p>
            <ul class="social">
              <li>
                <a
                  href="https://www.linkedin.com/in/fernando-morales-chavez-649029144"
                  target="_blank"
                  ><fa-icon [icon]="faLinkedin" [inverse]="true"></fa-icon
                ></a>
              </li>
            </ul>
          </div>
          <!-- End Column -->

          <div class="col-md-7 px-0 pl-md-3">
            <h3>Contact Me</h3>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="messages"></div>
              <div class="controls">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    placeholder="Enter your name"
                  />
                </div>

                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    formControlName="email"
                    placeholder="Enter your email"
                  />
                </div>

                <div class="form-group">
                  <textarea
                    name="message"
                    rows="4"
                    class="form-control"
                    formControlName="message"
                    placeholder="Add your message"
                  ></textarea>
                </div>

                <input
                  type="submit"
                  class="btn btn-outline-light btn-sm"
                  value="Send Message"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
