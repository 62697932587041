import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GalleryComponent } from './gallery/gallery.component';
import { ImagePopupComponent } from './gallery/image-popup/image-popup.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';

@NgModule({
  declarations: [NavBarComponent, GalleryComponent, ImagePopupComponent],
  imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule],
  exports: [
    FontAwesomeModule,
    NavBarComponent,
    GalleryComponent,
    ReactiveFormsModule,
  ],
})
export class SharedModule {}
