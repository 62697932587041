import { Component, OnInit } from '@angular/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { NavBarService } from 'src/shared/nav-bar/nav-bar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  faAngleDown = faAngleDown;

  constructor() {}

  ngOnInit() {}

  scroll(element) {
    NavBarService.scroll(element);
  }
}
