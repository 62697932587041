<div id="skills" #target>
  <div class="fixed">
    <div class="row dark text-light text-center">
      <div class="col-12 os-animation animated fadeInUp" data-aos="fade-up">
        <h3 class="heading">Technologies</h3>
        <div class="heading-underline"></div>
      </div>

      <div class="col-md-3 os-animation" data-aos="fade-left">
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead"
          ><p>Javascript</p>
          since 2000 (professional 2009)</span
        >
      </div>

      <div class="col-md-3 os-animation" data-aos="fade-up">
        <!-- <h3>Angular</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-angular"></i>
          </span>
        </div>
        <span class="lead"
          ><p>Angular</p>
          since 2015</span
        >
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>.NET Core</p>
          since 2015
        </span>
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>Snowflake</p>
          since 2021
        </span>
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>SQL Server</p>
          since 2009
        </span>
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>Python</p>
          since 2012
        </span>
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>Node JS</p>
          since 2014
        </span>
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>Mongo DB</p>
          since 2018
        </span>
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>Docker</p>
          since 2018
        </span>
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>JAVA</p>
          since 2009
        </span>
      </div>

      <div
        class="col-md-3 os-animation animated fadeInLeft"
        data-animation="fadeInLeft"
      >
        <!-- <h3>Javascript</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-js"></i>
          </span>
        </div>
        <span class="lead">
          <p>GIT</p>
          since 2012
        </span>
      </div>

      <div class="col-md-3 os-animation" data-aos="fade-right">
        <!-- <h3>CSS 3</h3> -->
        <div class="feature py-2">
          <span class="fa-layers fa-3x">
            <i class="fab fa-php"></i>
          </span>
        </div>
        <span class="lead"
          ><p>PHP</p>
          since 2009</span
        >
      </div>
    </div>

    <!-- End Row Dark -->

    <div class="fixed-wrap">
      <div class="fixed-dark"></div>
    </div>
  </div>
</div>
