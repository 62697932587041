<div id="knowledge" class="offset">
  <div class="container-fluid px-0">
    <div class="row justify-content-center py-5">
      <div class="col-12 mt-5 os-animation" data-aos="fade-up">
        <h3 class="heading">Knowledge</h3>
        <div class="heading-underline"></div>
      </div>
    </div>
  </div>
</div>
