<!-- Start aboutMe Section -->
<div id="aboutMe" class="offset">
  <!-- Start Theme Heading -->
  <!-- <div
    class="row text-center py-4 os-animation justify-content-center"
    data-animation="fadeInUp"
  >
    <div class="col-11 col-md-10 col-lg-9">
      <h1>Nuno Responsive Bootstrap Theme</h1>
      <p class="lead">
        Bootstrap is an open-source front-end library with HTML and CSS based
        designs. This website is built with HTML5, CSS3 and Bootstrap 4.
      </p>
      <a href="#contact" class="btn btn-secondary btn-sm">Request A Quote</a>
      <a href="#portfolio" class="btn btn-nuno btn-sm">See Our Portfolio</a>
    </div>
  </div> -->
  <!-- End Theme Heading -->

  <!-- Start Jumbotron -->
  <div
    class="jumbotron full-height m-0 py-5 height-100"
    data-aos="fade-up"
    data-aos-delay="100"
  >
    <div class="col-12 os-animation">
      <h3 class="heading">About Me</h3>
      <div class="heading-underline"></div>
    </div>

    <div class="container">
      <!-- <div class="row text-center px-lg-4 px-xl-5">
        <div
          class="col-sm-6 col-md-4 os-animation animated fadeInLeft"
          data-aos="fade-left"
        >
          <div class="feature px-2">
            <span class="fa-stack fa-2x">
              <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <fa-icon
                [icon]="faJs"
                stackItemSize="1x"
                [inverse]="true"
              ></fa-icon>
              <i class="fab fa-js fa-stack-1x fa-inverse"></i>
            </span>
            <h3>Mobile Friendly</h3>
            <p class="lead">
              Nuno aboutMe a mobie friendly, responsive first website layout
              using Bootstrap.
            </p>
          </div>
        </div>

        <div
          class="col-sm-6 col-md-4 os-animation animated fadeInUp"
          data-aos="fade-up"
        >
          <div class="feature px-2">
            <span class="fa-stack fa-2x">
              <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <fa-icon
                [icon]="faDesktop"
                stackItemSize="1x"
                [inverse]="true"
                transform="shrink-1"
              ></fa-icon>
            </span>
            <h3>Full Screen Landing</h3>
            <p class="lead">
              Using Bootstrap, the Nuno theme aboutMe a full screen carousel
              landing page.
            </p>
          </div>
        </div>

        <div
          class="col-sm-6 col-md-4 os-animation animated fadeInRight"
          data-aos="fade-right"
        >
          <div class="feature px-2">
            <span class="fa-stack fa-2x">
              <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <fa-icon
                [icon]="faPlay"
                stackItemSize="1x"
                [inverse]="true"
                transform="shrink-3.5 right-1"
              ></fa-icon>
            </span>
            <h3>Custom Animation</h3>
            <p class="lead">
              Animate.css and Waypoints.js allow for smooth animations scrolling
              down the site.
            </p>
          </div>
        </div>

        <div
          class="col-sm-6 col-md-4 os-animation animated fadeInLeft"
          data-aos="fade-left"
        >
          <div class="feature px-2">
            <span class="fa-stack fa-2x">
              <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <fa-icon
                [icon]="faAngleDoubleDown"
                stackItemSize="1x"
                [inverse]="true"
              ></fa-icon>
            </span>
            <h3>Parallax Scrolling</h3>
            <p class="lead">
              Fixed background images allow the theme to have content-filled
              parallax scrolling sections.
            </p>
          </div>
        </div>

        <div
          class="col-sm-6 col-md-4 os-animation animated fadeInUp"
          data-aos="fade-up"
        >
          <div class="feature px-2">
            <span class="fa-stack fa-2x">
              <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <i
                class="fas fa-sliders-h fa-stack-1x fa-inverse"
                data-fa-transform="shrink-1"
              ></i>
            </span>
            <h3>Content Slider</h3>
            <p class="lead">
              Owl.Carousel.js makes navigating content sliders seamless with
              it's content carousel navigation.
            </p>
          </div>
        </div>

        <div
          class="col-sm-6 col-md-4 os-animation animated fadeInRight"
          data-aos="fade-right"
        >
          <div class="feature px-2">
            <span class="fa-stack fa-2x">
              <fa-icon [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <i class="fab fa-wpforms fa-stack-1x fa-inverse"></i>
            </span>
            <h3>Contact Form</h3>
            <p class="lead">
              The Bootsrap HTML form will send directly to your email address
              using PHPMailer.php.
            </p>
          </div>
        </div>
      </div> -->
      <div class="row">
        <p class="text">
          I'm a principal software engineer with 13+ years of experience. I have
          worked throughout the years with a lot of technologies, programming
          languages and types of projects. I have been involved in projects for
          the Mexican government, home brokers, real state, schools, and some
          other freelance projects for restaurants, rock bands, and many more. I
          have designed and architected whole applications from scratch, setting
          up all the bases and fundamentals. Writing documents of analysis,
          design, and implementation on how all layers need to be connected and
          interact with each other. I have written articles and documents of
          coding best practices, which I'm positive is what makes the difference
          between a good and an excellent software engineer.
        </p>
      </div>

      <div class="row d-flex flex-row-reverse bd-highlight">
        <blockquote>
          “Writing clean code is what you must do in order to call yourself a
          professional. There is no reasonable excuse for doing anything less
          than your best.” ― Robert C. Martin, Clean Code: A Handbook of Agile
          Software Craftsmanship
        </blockquote>
      </div>
    </div>
  </div>
</div>
