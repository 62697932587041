import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImagePopupComponent } from "src/shared/gallery/image-popup/image-popup.component";
import { NavBarService } from "src/shared/nav-bar/nav-bar.service";

@Component({
  selector: "app-certificates",
  templateUrl: "./certificates.component.html",
  styleUrls: ["./certificates.component.scss"],
})
export class CertificatesComponent implements OnInit {
  images = [
    {
      path: "assets/img/certifications/azure-fundamentals.png",
      name: "Microsoft Azure Fundamentals",
      link: "https://learn.microsoft.com/en-us/users/fernandomorales-4153/credentials/74b92e03f72fa864",
      displayOrder: -2,
    },
    {
      path: "assets/img/certifications/AI-900Badge.png",
      name: "Microsoft AI Fundamentals",
      link: "https://learn.microsoft.com/en-us/users/fernandomorales-4153/credentials/2519be7766786e6b",
      displayOrder: -1,
    },
    {
      path: "assets/img/certifications/nn-group.png",
      name: "NN Group UX Certified (1074047)",
      link: "https://www.nngroup.com/ux-certification/people/",
      displayOrder: 0,
    },
    {
      path: "assets/img/PluralsightCertifications/Android-Developing.png",
      name: "Android Developing",
      displayOrder: 100,
    },
    {
      path: "assets/img/PluralsightCertifications/Android-Fundamentals.png",
      name: "Android Fundamentals",
      displayOrder: 99,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Architecture.png",
      name: "Angular Architecture",
      displayOrder: 3,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Best-Practices.png",
      name: "Angular Best Practices",
      displayOrder: 4,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Build-Deploy-Cloud.png",
      name: "Angular Build Deploy Cloud",
      displayOrder: 10,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-CLI.png",
      name: "Angular CLI",
      displayOrder: 5,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Component-Communication.png",
      name: "Angular Component Communication",
      displayOrder: 6,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-CSS-grid.png",
      name: "Angular Css grid",
      displayOrder: 11,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-forms.png",
      name: "Angular Forms",
      displayOrder: 7,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Fundamentals.png",
      name: "Angular Fundamentals",
      displayOrder: 1,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Getting-Started.png",
      name: "Angular Getting Started",
      displayOrder: 2,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Http-Communication.png",
      name: "Angular Http Communication",
      displayOrder: 8,
    },
    {
      path: "assets/img/PluralsightCertifications/AngularJs-Best-Practices.png",
      name: "AngularJs Best Practices",
      displayOrder: 98,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Json-Web-Tokens.png",
      name: "Angular Json Web Tokens",
      displayOrder: 9,
    },
    {
      path: "assets/img/PluralsightCertifications/AngularJs-Patterns.png",
      name: "AngularJs Patterns",
      displayOrder: 97,
    },
    {
      path: "assets/img/PluralsightCertifications/AngularJs-Routing.png",
      name: "AngularJs Routing",
      displayOrder: 96,
    },
    {
      path: "assets/img/PluralsightCertifications/AngularJs-Services.png",
      name: "AngularJs Services",
      displayOrder: 95,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Ngrx.png",
      name: "Angular Ngrx",
      displayOrder: 9,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Quick-Start.png",
      name: "Angular Quick Start",
      displayOrder: 13,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Reactive-Forms.png",
      name: "Angular Reactive Forms",
      displayOrder: 14,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Routing.png",
      name: "Angular Routing",
      displayOrder: 15,
    },
    {
      path: "assets/img/PluralsightCertifications/Angular-Unit-Testing.png",
      name: "Angular Unit Testing",
      displayOrder: 16,
    },
    {
      path: "assets/img/PluralsightCertifications/Better-Css-Less-Sass.png",
      name: "Better Css Less Sass",
      displayOrder: 40,
    },
    {
      path: "assets/img/PluralsightCertifications/CSharp-Best-Practices.png",
      name: "C# Best Practices",
      displayOrder: 50,
    },
    {
      path: "assets/img/PluralsightCertifications/Css-Positioning.png",
      name: "Css Positioning",
      displayOrder: 41,
    },
    {
      path: "assets/img/PluralsightCertifications/Customizing-Angular-CLI.png",
      name: "Customizing Angular CLI",
      displayOrder: 17,
    },
    {
      path: "assets/img/PluralsightCertifications/Data-Composition-Rxjs.png",
      name: "Data Composition Rxjs",
      displayOrder: 18,
    },
    {
      path: "assets/img/PluralsightCertifications/ES6-Training.png",
      name: "ES6 Training",
      displayOrder: 19,
    },
    {
      path: "assets/img/PluralsightCertifications/How-Git-Works.png",
      name: "How Git Works",
      displayOrder: 20,
    },
    {
      path: "assets/img/PluralsightCertifications/Introduction-Css.png",
      name: "Introduction Css",
      displayOrder: 42,
    },
    {
      path: "assets/img/PluralsightCertifications/Introduction-Node-js.png",
      name: "Introduction Node js",
      displayOrder: 31,
    },
    {
      path: "assets/img/PluralsightCertifications/Ionic.png",
      name: "Ionic",
      displayOrder: 21,
    },
    {
      path: "assets/img/PluralsightCertifications/JS-Design-Patterns.png",
      name: "Js Design Patterns",
      displayOrder: 22,
    },
    {
      path: "assets/img/PluralsightCertifications/Js-Objects-Prototypes.png",
      name: "Js Objects Prototypes",
      displayOrder: 23,
    },
    {
      path: "assets/img/PluralsightCertifications/JS-Quick-Start.png",
      name: "Js Quick Start",
      displayOrder: 24,
    },
    {
      path: "assets/img/PluralsightCertifications/NPM-Playbook.png",
      name: "Npm Playbook",
      displayOrder: 25,
    },
    {
      path: "assets/img/PluralsightCertifications/PWA-Angular-Ionic.png",
      name: "Pwa Angular Ionic",
      displayOrder: 26,
    },
    {
      path: "assets/img/PluralsightCertifications/PWA-Big-Picture.png",
      name: "Pwa Big Picture",
      displayOrder: 27,
    },
    {
      path: "assets/img/PluralsightCertifications/PWA-Ionic.png",
      name: "Pwa Ionic",
      displayOrder: 28,
    },
    {
      path: "assets/img/PluralsightCertifications/React-Big-Picture.png",
      name: "React Big Picture",
      displayOrder: 54,
    },
    {
      path: "assets/img/PluralsightCertifications/Reactive-Angular-min.png",
      name: "Reactive Angular",
      displayOrder: 55,
    },
    {
      path: "assets/img/PluralsightCertifications/Rxjs-min.png",
      name: "Rxjs",
      displayOrder: 29,
    },
    {
      path: "assets/img/PluralsightCertifications/TDD-Big-Picture-min.png",
      name: "Tdd Big Picture",
      displayOrder: 30,
    },
    {
      path: "assets/img/PluralsightCertifications/UX-Fundamentals-min.png",
      name: "Ux Fundamentals",
      displayOrder: 43,
    },
    {
      path: "assets/img/PluralsightCertifications/Vs-code-min.png",
      name: "Vs Code",
      displayOrder: 80,
    },
  ];
  private readonly maxWidthToDisplayPopUpImage = 1000;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.images.sort((img1, img2) => img1.displayOrder - img2.displayOrder);
  }

  openImage(image) {
    if (window.screen.width >= this.maxWidthToDisplayPopUpImage) {
      const modal = this.modalService.open(ImagePopupComponent, { size: "lg" });
      modal.componentInstance.image = image.path;
      modal.componentInstance.title = image.name;
    }
  }

  scroll(element) {
    NavBarService.scroll(element);
  }
}
