<nav
  class="navbar navbar-expand-lg fixed-top"
  [ngClass]="{ solid: displaySolid }"
>
  <div class="container-fluid">
    <!-- <a href="#home" class="navbar-brand">
      <img src="img/nuno.png" alt=""/>
      <span class="f">f</span
      ><span class="mySite"
        >ind<span class="uppercase">f</span>ernando.com</span
      >
    </a> -->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarResponsive"
    >
      <span class="custom-toggler-icon"
        ><fa-icon [icon]="faBars"></fa-icon
      ></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item pointer">
          <a
            (click)="scroll('home')"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            >Home</a
          >
        </li>
        <li class="nav-item pointer">
          <a
            (click)="scroll('aboutMe')"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            >About me</a
          >
        </li>
        <li class="nav-item pointer">
          <a
            (click)="scroll('skills')"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            >Technologies</a
          >
        </li>
        <li class="nav-item pointer">
          <a
            (click)="scroll('experience')"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            >Experience</a
          >
        </li>
        <!-- <li class="nav-item pointer">
          <a (click)="scroll('knowledge')" class="nav-link">Knowledge</a>
        </li> -->
        <li class="nav-item pointer">
          <a
            (click)="scroll('certificates')"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            >Certificates</a
          >
        </li>
        <li class="nav-item pointer">
          <!-- <a (click)="downloadResume($event)" class="nav-link">Resume</a> -->
          <a
            href="assets/Fernando_Morales.pdf"
            download="Fernando-Morales.pdf"
            target="_blank"
            class="nav-link"
            >Resume</a
          >
        </li>
        <li class="nav-item pointer">
          <a
            (click)="scroll('contact')"
            class="nav-link"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            >Contact</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
